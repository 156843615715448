import {getActivityStatus} from "../../api/activity";
import {setStore,getStore} from "../../utils/store";
// 活动是否开始
const activity={
    state:{
        activityStatus:getStore({ name: 'activityStatus'}) || false
    },
    actions:{
        QUERY_ACTIVITY_STATUS(state){
            // 因vuex赋值和使用有时间偏差,所以选择Promise
            return  new Promise((resolve, reject)=>{
                   getActivityStatus().then(res=>{
                    const status=JSON.parse(res.data)
                    state.activityStatus=status
                    setStore({name:'activityStatus',content:status})
                    resolve(status)
                })
            })
        },
    },
}

export default activity
