import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import wordExercise from './modules/wordExercise'
import typing from './modules/typing'
import topNav from './modules/topNav'
import getters from './getters'
import dict from './modules/dict'
import activity from "./modules/activity";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    dict,
    topNav,
    typing,
    wordExercise,
    activity
  },
  getters,
  state: {
    currentNode:{}
  },
  mutations: {
    SET_NODE: (state, currentNode) => {
      state.currentNode = currentNode
    },
  },
  actions: {
  },
})
