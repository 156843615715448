import request from "@/utils/request";

export function getDynamicsList(data) {
  return request({
    url: `api/tmHome/adCompanydynamicsList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
export function getCourseList(data) {
  return request({
    url: `api/tmHome/adPropagateList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//密码登录
export function getLogin(data) {
  data.password= window.btoa(data.password)
  return request({
    url: `api/tmStudentLogin/login`,
    method: 'post',
    data
  })
}
//学员风采
export function faceShowList(data) {
  return request({
    url: `api/tmFaceShow/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//学员修改密码
export function changePassword(data) {
  data.password=window.btoa(data.password)
  data.newPassword=window.btoa(data.newPassword)
  return request({
    url: `api/tmPassword/passwordEdit`,
    method: 'post',
    data
  })
}
//意见反馈
export function feedBackAdd(data) {
  return request({
    url: `api/tmStudentFeedback/add`,
    method: 'post',
    data
  })
}
//学员信息编辑
export function studentEdit(data) {
  return request({
    url: `api/tmStudentUser/updateUrl`,
    method: 'post',
    data
  })
}
//登录广告
export function loginAd(data) {
  return request({
    url: `api/tmWelcome/listUp`,
    method: 'post',
    data
  })
}
//更改广告状态
export function AdChange(data) {
  return request({
    url: `api/tmWelcome/updateUp`,
    method: 'post',
    data
  })
}
//消息删除
export function msgDel(data) {
  return request({
    url: `api/tmNoticeLog/remove?ids=${data}`,
    method: 'post',
  })
}

//公司动态或课程宣传详情
export function getDetailInfo(data) {
  return request({
    url: `api/tmHome/getAdInfo`,
    method: 'post',
    data
  })
}
