<template>
  <!--  登陆以后的头部-->
  <div class="index">
    <!--头部-->
    <IndexHeader>
      <!--      tab切换-->
      <div class="tab">
        <router-link to="/home">
          <div :class="{ select: this.$route.path==='/home' }">首页</div>
        </router-link>
        <div class="stick" />
        <router-link to="/videoPage">
          <div :class="{ select: ['/material','/problem','/problemDetail','/videoPage'].includes(this.$route.path) }">
            我要学习
          </div>
        </router-link>
        <div class="stick" />
        <router-link to="/homework">
          <div
            :class="{ select:['/homework','/test','/exercises','/homeworkDetail','/testDetail','/exercisesDetail','/wordLearning','/wordDictation'].includes(this.$route.path) }"
          >
            课程作业
          </div>
        </router-link>
        <div class="stick" />

        <router-link to="/answerQuestions">
          <div :class="{ select: ['/answerQuestions','/answerQuestionsDetail'].includes(this.$route.path) }">难题解答
          </div>
        </router-link>
        <div class="stick" />
        <div
          :class="{ select: ['/typingSetting','/typingStart','/interviewQuestionBank','/wordExercise'].some(item => this.$route.path.includes(item)) }"
          class="nav-dropdown-box"
          @mouseenter="dropdownShow=true"
          @mouseleave="dropdownShow=false"
        >
          <span>基本功测评</span>
          <div v-if="dropdownShow" class="dropdown-box">
            <router-link to="/typingSetting">
              <div
                :class="{ select: ['/typingSetting','/typingStart'].includes(this.$route.path) }"
                @click="closeDropdownNav"
              >打字评测
              </div>
            </router-link>
            <router-link to="/interviewQuestionBank">
              <div :class="{ select: ['/interviewQuestionBank'].includes(this.$route.path) }" @click="closeDropdownNav">
                面试题库
              </div>
            </router-link>
            <router-link to="/wordExercise">
              <div :class="{ select:this.$route.path.includes('/wordExercise') }" @click="closeDropdownNav">专业英语测评
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.user.token" class="info">
        <el-dropdown ref="messageDrop" class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <el-badge :value="total" :max="99" class="item" :hidden="total===0">
              <!--                铃铛-->
              <!--                <i class="el-icon-message-solid"></i>-->
              <div class="Bell" style="font-size: 18px;">
                <i class="el-icon-message-solid" />
              </div>
              <div class="stick" />

            </el-badge>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 480px;height: 530px;padding: 0 ">
            <div class="title" style="">通知消息</div>
            <el-dropdown-item divided />
            <el-dropdown-item v-if="list.length===0" style="padding: 100px 20px">
              <div style="width: 100px;height: 140px;margin: 0 auto;">
                <img src="../assets/images/empty.png" style="width: 100%;height: 100px">
                <div style="padding-left: 20px">暂无消息</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-for="(i,index) in list" :key="index" style="padding: 0" @click.native="getDetail(i)">
              <div class="item-box" style="overflow: hidden;padding: 0">
                <div style="float: left;padding-left: 31px;padding-top: 15px">
                  <el-badge is-dot class="item" :hidden="i.status===1">
                    <div class="XiaoXi"><i class="el-icon-message-solid" style="font-size: 25px" /></div>
                  </el-badge>
                </div>
                <div style="float: left;margin-left: 20px;width: 350px;height: 68px;overflow:hidden;">
                  <div style="overflow: hidden;">
                    <div
                      :class="{'read':i.status===1}"
                      style="float: left;font-size: 16px;padding-top: 4px;font-weight: 600;width: 180px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                    >{{ i.title }}
                    </div>
                    <div :class="{'read':i.status===1}" style="float: right;font-size: 14px;color: #999999">
                      {{ i.createTime }}
                    </div>
                  </div>
                  <div
                    :class="{'read':i.status===1}"
                    style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis"
                  >{{ i.content }}
                  </div>
                </div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided />
            <div v-if="list.length>0" class="bottom" style="margin-bottom: 10px;">
              <div style="color:#4492FD " @click="allRead">全部标为已读</div>
              <div style="color:#4492FD " @click="getAll(initPage)">历史消息</div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <div>
          <span
            style="font-size: 16px;font-family: MicrosoftYaHei;color: #333333;margin: 0 10px;width: 70px;overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;display:inline-block;"
          >Hi,{{ userInfo.name }}</span>
        </div>
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper" style="width: 32px;height: 32px;background: #4C97FD;border-radius: 32px;">
            <img :src="userInfo.url==null?$pic:$downloadUrl + userInfo.url" class="user-avatar">
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/personal">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else class="info" style="margin-left: 100px;">
        <!--      铃铛-->
        <div v-show="!this.$store.state.user.token" class="Bell">
          <i class="el-icon-message-solid" />
        </div>
        <!--             边框-->
        <div v-show="!this.$store.state.user.token" class="stick" style="margin-left: 20px;" />
        <!--      登录-->
        <div v-if="!this.$store.state.user.token" class="HeaderLogin">
          <div class="Login" @click="toLogin"><a href="#">登录</a></div>
        </div>
      </div>
    </IndexHeader>
    <!--  ？？？  -->
    <messageAll
      :dialog-visible="msgAllShow"
      :list-all="listAll"
      :total="allTotal"
      @close="close"
      @getAll="getAll"
      @getList="getList"
    />
    <message-detail :detail="detail" :detail-show="detailShow" @detailClose="detailClose" @getList="getList" />
    <!--    右侧固定栏-->
    <div class="rightTab" style="cursor: pointer">
      <div class="box">
        <div class="feedback" @click="feedBackShow">
          <div class="before" style="width: 68px;height: 72px;">
            <img src="../assets/images/feed_default.png" alt="">
          </div>
          <div class="after" style="width: 68px;height: 72px;">
            <img src="../assets/images/feed_selected.png" alt="">
          </div>
        </div>
      </div>

      <div v-for="(item,index) in qqList" :key="index">
        <div class="box ">
          <div class="qqbox">
            <div class="before" style="width: 68px;height: 72px;">
              <img src="../assets/images/qq_default.png" alt="">
            </div>
            <a :href="item" target="_blank">
              <img src="../assets/images/qq_selected.png" alt="">
            </a>
          </div>
        </div>
      </div>

      <div v-if="isShowGoTop" class="box">
        <div class="top">
          <i class="el-icon-top before" />
          <div class="after" style="border-radius:0 0 6px 6px">
            <a
              href="javascript:scroll(0,0)"
              style="border-radius:0 0 6px 6px;background-color: #157dfb"
            >返回<br>顶部</a></div>
        </div>
      </div>
      <feedBack :dialog-visible="backShow" @feedBackClose="feedBackClose" />
    </div>
    <!--    ？？？-->
    <div class="changeWidth">
      <el-dialog
        ref="dialog"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="800px"
        @close="handleClose"
      />
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="adDetailShow"
      width="30%"
      @close="adDetailShow = false"
    >
      <div v-if="adDetail" v-html="adDetail.content" />
    </el-dialog>
    <router-view />
  </div>
</template>

<script>
import feedBack from '../components/feedBack'
import messageAll from '../components/messageAll'
import MessageDetail from '../components/messageDetail'
import { getAllRead, getMessage, getRead, getUnRead } from '../api/login'
import { mapState } from 'vuex'
import { AdChange, loginAd } from '../api/welcome'
import IndexHeader from '@/components/IndexHeader'

export default {
  name: 'IndexLayout',
  components: { MessageDetail, messageAll, IndexHeader, feedBack },
  data() {
    return {
      // 基本功评测下拉框
      dropdownShow: false,
      detail: {},
      phone: '',
      adDetail: {},
      dialogVisible: false,
      backShow: false,
      msgAllShow: false,
      detailShow: false,
      adDetailShow: false,
      total: 0,
      allTotal: 0,
      list: [],
      listAll: [],
      isShowGoTop: false,
      initPage: {
        pageNum: 1,
        pageSize: 6
      },
      qqList: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    userInfo() {
      this.getList()
    }
  },
  created() {
    this.getList()
    setInterval(() => {
      this.getList()
    }, 60000)
    // 处理qq客服的请求地址
    this.dealWithQQList()
    this.phone = this.$store.state.dict.dictTree.es_call['remark'].toString()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    const titleBoxs = document.getElementsByClassName('top')
    titleBoxs[0].style.width = window.innerWidth + 'px'
    this.$nextTick(() => {
      this.$refs.dialog.$el.addEventListener('click', (data) => {
        if (data.path.length > 10) {
          this.dialogVisible = false
        } else {
          if (this.adDetail.jumpWay === 0) {
            this.adDetailShow = true
            this.dialogVisible = false
          } else {
            window.open(this.adDetail.content)
            this.dialogVisible = false
          }
        }
      })
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    // 退出
    logout() {
      this.$store.dispatch('FedLogOut')
      window.location.replace('/index')
    },
    getList() {
      getMessage({ studentId: this.userInfo.id, pageSize: 6, pageNum: 1 }).then(res => {
        if (res.code === 200) {
          this.list = res.rows
          if (this.$route.path === '/home') {
            loginAd({ studentId: this.$store.state.user.userInfo.id }).then(res => {
              if (res.code === 200) {
                if ('data' in res) {
                  this.adDetail = res.data
                  this.dialogVisible = true
                  const url = this.$downloadUrl + res.data.fileUrls
                  this.$refs.dialog.$el.firstChild.style.background = `url(${url}) no-repeat 100% 100% `
                  this.$refs.dialog.$el.firstChild.style.backgroundSize = 'cover'
                  AdChange({ id: this.adDetail.id }).then(res => {
                    console.log(res)
                  })
                }
              }
            })
          }
          getUnRead({ studentId: this.userInfo.id }).then(res => {
            if (res.code === 200) {
              this.total = res.data
            }
          })
        }
      })
    },
    getDetail(data) {
      if (JSON.stringify(data.genre) !== 'null') {
        getRead({ id: data.id, status: 1 }).then(res => {
          if (res.code === 200) {
            getMessage({ studentId: this.userInfo.id, pageSize: 6, pageNum: 1 }).then(res => {
              if (res.code === 200) {
                this.list = res.rows
              }
              getUnRead({ studentId: this.userInfo.id }).then(res => {
                if (res.code === 200) {
                  this.total = res.data
                }
              })
            })
          }
        })
        if (data.genre === 0) {
          this.$router.push({ name: 'homeworkDetail', query: { id: data.genreId }})
        } else if (data.genre === 1) {
          this.$router.push({ name: 'test', query: { id: data.genreId }})
        } else if (data.genre === 3) {
          this.$router.push({ name: 'answerQuestionsDetail', query: { id: data.genreId }})
        } else {
          this.$router.push('/exercises')
        }
      } else {
        this.detailShow = true
        this.detail = data
      }
    },
    feedBackShow() {
      this.backShow = true
    },
    feedBackClose() {
      this.backShow = false
    },
    allRead() {
      getAllRead({ studentId: this.userInfo.id, status: 1 }).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getList()
        }
      })
    },
    // 获取全部消息
    getAll(data) {
      console.log(data)
      this.$refs.messageDrop.hide()
      this.msgAllShow = true
      getMessage({ studentId: this.userInfo.id, ...data }).then(res => {
        if (res.code === 200) {
          this.listAll = res.rows
          this.allTotal = res.total
        }
      })
    },
    close() {
      this.msgAllShow = false
    },
    detailClose() {
      this.detailShow = false
      this.getList()
    },
    handleScroll() {
      // 滚动条滚动时，距离顶部的距离
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 判断是否下拉了所需长（高）度，若满足，则返回顶部图标出现
      if (this.scrollTop > 450) {
        this.isShowGoTop = true
      } else {
        this.isShowGoTop = false
      }
    },
    // 处理qq客服的请求地址
    dealWithQQList() {
      this.qqList = this.$store.state.dict.dictTree.es_call['remark']
      this.qqList.forEach(function(item, index, arr) {
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          arr[index] = '/openqq.html?qqNum=' + item
        } else {
          arr[index] = 'http://wpa.qq.com/msgrd?v=3&uin=' + item + '&site=qq&menu=yes'
        }
      })
      console.log(this.qqList)
    },
    closeDropdownNav() {
      this.dropdownShow = false
    }
  }
}
</script>

<style scoped lang="less">
.select {
  width: 100px;
  height: 30px;
  //background:rgba(12, 3, 7, .3);
  //border-radius: 15px;
  color: rgba(67, 146, 253, 1) !important;
  font-weight: bold;
}

.read {
  color: rgba(0, 0, 0, 0.25);;
}

.XiaoXi {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #39A5EF;
  text-align: center;
  line-height: 20px;
  color: #fff;

  .el-icon-message-solid {
    font-size: 25px;
    position: absolute;
    top: 5px;
    left: 7px;
  }
}

::v-deep.el-dropdown-menu {
  > .title {
    text-align: center;
    color: #409EFF;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    letter-spacing: 3px;
  }

  > .bottom {
    display: flex;
    justify-content: space-between;

    div {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding: 15px;
    }

    > div:hover {
      cursor: pointer;
      color: #39A5EF;
    }
  }

  .item-box {
    > .top {
      display: flex;
      align-items: center;

      .img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #39A5EF;
        text-align: center;
        line-height: 20px;
        color: #fff;

        i {
          margin-right: 0;
        }
      }

      > .title {
        margin-left: 10px;
        width: 259px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      > .time {
        margin-left: 30px;
      }
    }

    > .content {
      width: 430px;
      margin: 0 auto;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

.index {
  ::v-deep.el-dialog {

    /*max-height: 600px;*/
    min-height: 500px;
    /*background: #761717;*/
  }

  .user-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  > .top {
    //height: 80px;
    height: 70px;
    width: 100% !important;
    padding-right: 14px !important;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 99;

    .box {
      cursor: pointer;
      margin: 0 auto;
      //padding: 10px 0;
      width: 1200px;
      height: 70px;
      //height: 100%;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }

    .logo {
      float: left;
      //margin-left: 13px;
      padding: 12px 0 11px 19px;
      width: 53px;
      height: 55px;

      img {
        width: 53px;
        height: 55px;
      }

      //font-size: 30px;
      //font-family: JBeiWeiKaiSu;
      //align-items: center;
      //display: flex;
      //font-weight: 400;
      //color: #FFFFFF;
      //line-height: 72px;
      //p{
      //    margin: 0 10px;
      //}
    }

    .name {
      float: left;
      height: 20px;
      font-size: 20px;
      font-family: HYk2gj;
      color: #4C97FD;
      line-height: 70px;
      margin-left: 32px;
    }

    .tab {
      //display: flex;
      //width: 400px;
      float: left;
      height: 70px;
      margin-left: 122px;

      //line-height: 70px;
      //margin-left: -300px;
      /*justify-content: space-around;*/

      div {
        float: left;
        user-select: none;
        //margin-left: 30px;
        height: 70px;
        line-height: 70px;
        width: 114px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        //margin-left: 20px;
        //font-family: Microsoft YaHei;
        //font-weight: 400;
        color: rgba(48, 48, 48, 1);
      }

      .nav-dropdown-box {
        position: relative;

        .dropdown-box {
          position: absolute;
          top: 70px;
          left: 15px;
          width: 140px;
          height: 134px;
          font-size: 14px;
          background: #FFFFFF;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
          border-radius: 4px;
          padding: 4px 20px;

          div {
            font-weight: normal;
            height: 20px;
            margin: 11px 0;
            line-height: 20px;
            width: 100%;
            color: #333333;

            &:hover {
              color: #4C97FD;
            }
          }
        }
      }

      .stick {
        float: left;
        margin-top: 27px;
        height: 16px;
        width: 1px;
        background: #E7EDF6;
      }

      div:hover {
        font-weight: bold;
        color: #4C97FD;
      }
    }

    .info {
      float: left;
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 183px;

      /deep/ .el-badge__content.is-fixed {
        right: 26px !important;
      }

      .Bell {
        float: left;
        color: #4C97FD;
        width: 14px;
        height: 18px;
        //font-size: 16px;
      }

      .stick {
        float: left;
        margin-left: 21px;
        width: 1px;
        height: 16px;
        background: #E7EDF6;
      }

      /*   .el-icon-bell {
           //margin-right: 20px;
           //margin: 23px 0 24px 248px;
           width: 14px;
           height: 18px;
           //background: #4C97FD;
           //line-height: 25px;

           padding-left: 248px;
           border-right: 1px solid #CBD2D6;
           font-size: 16px;
         }*/
    }

    .info:hover {
      cursor: pointer;
    }
  }

  .rightTab {
    z-index: 9999;
    position: fixed;
    right: 30px;
    top: calc(50vh - 100px);
    width: 68px;
    //height: 152px;
    background: #FFFFFF;
    //border: 1px solid #E6E9EF;
    border-radius: 6px;

    .box {
      width: 68px;
      height: 72px;
      border-bottom: 1px solid #E6E9EF;
      overflow: hidden;
      //cursor: pointer;
    }

    .before {
      color: #CBD1D5;
      text-align: center;
      font-size: 20px;
      line-height: 72px;
    }

    .after, a {
      background: #39A5EF;
      width: 68px;
      height: 72px;
      font-size: 15px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }

    .feedback, .phoneNum, .qqbox {
      width: 68px;
      height: 144px;

      &:hover {
        transform: translate(0, -50%);
      }
    }

    .top {
      width: 68px;
      height: 144px;

      &:hover {
        transform: translate(0, -50%);
      }
    }
  }
}

::v-deep.el-dialog {
  background: #4A6BD6;
}

.changeWidth ::v-deep.el-dialog {
  background-size: 100% 100% !important;
  min-height: 480px !important;

}

/deep/ .el-dropdown-menu__item {
  //padding: 0;
  line-height: 30px;
  color: rgba(48, 48, 48, 1);
}

/deep/ .el-badge__content.is-fixed {
  right: 110px;
}

/deep/ .el-badge__content.is-fixed.is-dot {
  top: 6px;
  right: 15px;
}

.allMark {
  color: #4492FD;
}

</style>
