<template>
  <div class="feedBack">
    <el-dialog
      title="意见反馈"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="537px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" class="demo-ruleForm">
        <el-form-item prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">实名</el-radio>
            <el-radio :label="1">匿名</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            :autosize="{ minRows: 12, maxRows: 12}"
            maxlength="500"
            resize="none"
            :show-word-limit="true"
            placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            round
            style="width: 160px;
height: 32px;border-radius: 179px;"
            @click="submitForm('form')"
          >提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { feedBackAdd } from '../api/welcome'

export default {
  name: 'FeedBack',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { type: 0 },
      rules: {
        type: [
          { required: true, message: '请选择方式', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('feedBackClose')
      this.form = { type: 0 }
      this.$refs['form'].clearValidate()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          feedBackAdd({ ...this.form, studentId: this.$store.state.user.userInfo.id, classId: this.$store.state.user.userInfo.classId }).then(res => {
            if (res.code === 200) {
              this.$message.success('提交成功')
              this.form = { type: 0 }
              this.$emit('feedBackClose')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
    //::v-deep .el-dialog {
    //    .el-dialog__header {
    //        width: 95%;
    //      font-size: 20px;
    //        //padding: 20px 20px 10px 2px;
    //        border-bottom: 1px solid #E8E8E8;
    //        margin: 0 auto;
    //    }
    //}
    //.feedBack {
    //
    //}
    /deep/.el-button,.el-button.is-round{
      padding: 0;
    }
    /deep/.el-radio__inner{
      width: 19px;
      height: 19px;
    }
    /deep/.el-radio__inner::after{
      width: 8px;
      height: 8px;
    }
    /deep/.el-dialog__title{
      color: rgba(67, 146, 253, 1);
    }
    .demo-ruleForm{
      text-align: center;

    }
    /deep/.el-dialog{
      height: 494px;
      text-align: center;
      color: rgba(67, 146, 253, 1);

    }

</style>
