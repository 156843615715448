// import { login, logout, getInfo } from '@/api/login'
// import { getToken } from '@/utils/auth'
// import { getStore ,setStore} from '@/utils/store'
// import { getUser } from '../../api/system/user'

const topNav = {
    state: {
        dialogVisible:false
    },

    mutations: {
        changeDialogVisible: (state, token) => {
            state.dialogVisible = token
        },
    },

}

export default topNav
