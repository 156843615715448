<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>
<script>
import { isMobile } from '@/utils/system'

export default {
  watch: {
    $route: {
      handler() {
        this.goPAGE()
      },
      deep: true
      // immediate: true
    }
  },
  async created() {
    this.mobilePageCompression()
    await this.$store.dispatch('GET_DICT_LIST')
  },
  methods: {
    mobilePageCompression() {
      if (isMobile()) {
        const height = document.documentElement.clientHeight // 获取当前可视区域的高度
        window.onload = function() { // 在页面整体加载完毕时
          document.getElementById('app').style.height = height + 'px'// 给根布局设置高度
        }
      }
    },
    // 判断是否是移动端
    goPAGE() {
      if (this.$route.path === '/submit' && !isMobile()) {
        this.$router.replace({
          path: 'index'
        })
      }

      const allow = ['/submit', '/invitation']
      if (allow.includes(this.$route.path)) return
      if (isMobile()) {
        this.$router.replace({
          path: 'notYetOpen'
        })
      }
    }
  }
}
</script>

<style lang="less">
.box {
  width: 1200px;
  margin: auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

body {
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow: auto;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
