<template>
  <div class="messageDetail">
    <el-dialog
      title="提示"
      :visible.sync="detailShow"
      width="1000px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div slot="title" class="header-title" style="padding: 20px 0">
        <span class="title-name" style="color: #409EFF">消息详情</span>
        <!--                <span class="title-read" @click="msgDel">删除</span>-->
      </div>
      <div class="box">
        <div class="item-box">
          <div style="float: left;" class="top">

            <div class="XiaoXi"><i class="el-icon-message-solid" style="font-size: 25px" /></div>

          </div>
          <div style="float: left;margin-left: 20px;height: 68px;width: 786px">
            <div style="overflow: hidden;">
              <div class="messageTitle">{{ detail.title }}</div>
              <div class="messageTime">{{ detail.createTime }}</div>
            </div>

          </div>
        </div>
        <!--                <div class="item-box">-->
        <!--                    <div class="top">-->
        <!--                        <div>-->
        <!--                            <div class="img">-->
        <!--                                <i class="el-icon-message-solid"></i>-->
        <!--                            </div>-->
        <!--                            <div class="title">{{detail.title}}</div>-->
        <!--                        </div>-->
        <!--                        <div class="time">{{detail.createTime}}</div>-->
        <!--                    </div>-->
        <!--                    <div class="content">{{detail.content}}</div>-->
        <!--                </div>-->
      </div>
      <div v-if="detail.content!=null&&detail.content!==''" class="messageContent">
        <div class="content-c">{{ detail.content }}</div>
      </div>
      <div v-else style="margin-top: 20px;">
        <el-empty description="暂无内容" :image="require('@/assets/images/empty.png')" />
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { getRead } from '../api/login'
import { msgDel } from '../api/welcome'

export default {
  name: 'MessageDetail',
  props: {
    detailShow: {
      type: Boolean,
      default: false
    },
    detail: Object
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    handleClose() {
      getRead({ id: this.detail.id, status: 1 }).then(res => {
        if (res.code === 200) {
          this.$emit('detailClose')
        }
      })
    },
    msgDel() {
      msgDel(this.detail.id).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.$emit('getList')
          this.$emit('detailClose')
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.XiaoXi{
  position: relative;
  width: 38px;
  height: 38px;    border-radius: 50%;
  background: #39A5EF;
  text-align: center;
  line-height: 20px;
  color: #fff;
  margin-left: 50px;
  .el-icon-message-solid{
    font-size: 25px;
    position: absolute;
    top: 5px;
    left: 7px;
  }
}
    .messageDetail{
        ::v-deep.el-dialog{
            //.el-dialog__header{
            //    border-bottom: 1px solid #e8e8e8;
            //    width: 96%;
            //    margin: 0 auto;
            //    padding: 20px 20px 10px 0;
            //    text-align: left;
            //}
            .el-dialog__body{
                padding: 15px 20px;
            }
            .header-title{
                >.title-name{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 600;
                    color: #333333;
                    line-height: 18px;
                  letter-spacing: 2px;
                }
                >.title-read{
                    padding-left: 920px!important;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                }
                >.title-read:hover,.title-empty:hover{
                    cursor: pointer;
                }
                .title-empty{
                    padding-left: 30px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                }
            }
            .box{
              height: 70px;
              width: 100%;
                .item-box{
                    >.top{
                        margin: 10px 0;
                        display: flex;
                        justify-content:space-between;
                        align-items: center;
                        font-size: 14px;
                        text-align: left;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 18px;
                        >div {
                            display: flex;
                        }
                        .img{
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background: #39A5EF;
                            text-align: center;
                            line-height: 20px;
                            color: #fff;
                            i{
                                margin-right: 0;
                            }
                        }
                        .title{
                            display: inline-block;
                            margin-left: 10px;
                            /*display: -webkit-box;*/
                            overflow: hidden;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp:1;
                        }
                        >.time{
                            /*margin-left: 700px;*/
                            margin-right: 25px;
                        }
                    }
                    >.content{
                        width: 1009px;
                        min-height: 200px;
                        text-align: left;
                        margin: 0 auto;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                    }
                }
            }
        }
    }
    .moreText{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 15;
      overflow : hidden;
    }
    ///deep/.el-dialog__headerbtn{
    //  display: none;
    //}
.messageTitle{
  float: left;
  font-size: 16px;
  text-align: left;
  width: 600px;
  padding: 8px 0;
  font-weight: 600;
  letter-spacing: 2px;
}
.messageTime{
  float: right;
  font-size: 14px;
  line-height: 57px;
  color: #c3c3c5;
}
.messageContent{
  width: 850px;
  color: #666666;
  font-size: 14px;
  margin: 10px auto;
.content-c{
  color: #7e7d80;
  overflow: auto;
  height: 250px;
  margin-top: 5px;
  text-align: left;
  letter-spacing: 2px;
  font-family: Microsoft YaHei;
}
}
</style>
