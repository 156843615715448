import { saveAs } from 'file-saver'
import axios from 'axios'
import { Message } from 'element-ui'
import {getStore} from './store'
import vue from "vue";
// const baseURL = process.env.VUE_APP_BASE_API
function downloadHandle(url) {
    const a = document.createElement('a')
    a.href = url
    a.click()
    a.remove()
    setTimeout(() => {
      Message.success('正在下载中');
    },100)
}
export default {
  name(path, _isDelete = true) {
    const BASEURL = vue.prototype.$downloadUrl
    let url = `${BASEURL}${path}`
    downloadHandle(url)
  },
  resource(resource) {
    var url =  "api/common/download/resource?resource=" + encodeURI(resource);
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: { 'Authorization': 'Bearer ' + getStore({name:'token'}) }
    }).then(async (res) => {
      const isLogin = await this.blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data])
        this.saveAs(blob, decodeURI(res.headers['download-filename']))
      } else {
        Message.error('无效的会话，或者会话已过期，请重新登录。');
      }
    })
  },
  // zip(url, name) {
  //   var url = baseURL + url
  //   axios({
  //     method: 'get',
  //     url: url,
  //     responseType: 'blob',
  //     headers: { 'Authorization': 'Bearer ' + getToken() }
  //   }).then(async (res) => {
  //     const isLogin = await this.blobValidate(res.data);
  //     if (isLogin) {
  //       const blob = new Blob([res.data], { type: 'application/zip' })
  //       this.saveAs(blob, name)
  //     } else {
  //       Message.error('无效的会话，或者会话已过期，请重新登录。');
  //     }
  //   })
  // },
  saveAs(text, name, opts) {
    saveAs(text, name, opts);
  },
  async blobValidate(data) {
    try {
      const text = await data.text();
      console.log(text)
      JSON.parse(text+text);
      return false;
    } catch (error) {
      return true;
    }
  },
}

