import request from "@/utils/request";

//查询疑难问题列表
export function getActivityRuleHtml(id) {
    return request({
        url: `/api/student/large/turntable/rule/${id}`,
        method: 'get',
    })
}


export function getDrawRecordList() {
    return request({
        url: `/api/student/large/turntable/list`,
        method: 'get',
    })
}

//获取大转盘活动基本信息详细信息
export function getTurnTableInfo() {
    return request({
        url: `api/student/large/turntable/info`,
        method: 'get',
    })
}


//获取大转盘活动的剩余抽奖次数
export function getTurnTableNum(id) {
    return request({
        url: `api/student/large/turntable/num/${id}`,
        method: 'get',
    })
}

//中奖横向滚动轮播数据
export function getRollList(id) {
    return request({
        url: `api/student/large/turntable/roll/list/${id}`,
        method: 'get',
    })
}

//查看活动规则
export function getTurntableRule(id) {
    return request({
        url: `api/student/large/turntable/rule/${id}`,
        method: 'get',
    })
}

//大转盘抽奖
export function getTurntableDraw(id) {
    return request({
        url: `api/student/large/turntable/draw/${id}`,
        method: 'get',
    })
}
// 广告信息
export function getPosterData() {
    return request({
        url: `api/testQuestion/map/list`,
        method: 'get',
        data:{type:'移动端'}
    })
}

// 获取活动状态,返回值true/false; true显示首页转盘活动
export function getActivityStatus() {
    return request({
        url: `api/student/large/turntable/open`,
        method: 'get',
    })
}

