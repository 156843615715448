<template>

  <!--头部组件-->
  <div class="top" :style="$store.state.topNav.dialogVisible?'padding':'padding:0px'">
    <div class="box">
      <div class="logo">
        <img src="../assets/images/logo1.png" alt="">
      </div>
      <div class="name">教学管理云平台2.0</div>
      <slot></slot>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'

export default {
name: "IndexHeader",
  data(){
    return{
      detail:{},
      phone:'',
      adDetail:{},
      dialogVisible:false,
      backShow:false,
      msgAllShow:false,
      detailShow:false,
      adDetailShow:false,
      total:0,
      allTotal:0,
      list:[],
      listAll:[],
      isShowGoTop: false,
      initPage:{
        pageNum:1,
        pageSize:6
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    userInfo () {
      // this.getList()
    }
  },
  methods:{
    handleClose(){
      this.dialogVisible =false
    },
    //退出
    logout(){
      this.$store.dispatch('FedLogOut')
      window.location.replace('/index')
    },
  }

}
</script>

<style scoped lang="less">
.top {
  height: 70px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
}
.read{
  color: rgba(0, 0, 0, 0.25);;
}
.XiaoXi{
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #39A5EF;
  text-align: center;
  line-height: 20px;
  color: #fff;

  .el-icon-message-solid{
    font-size: 25px;
    position: absolute;
    top: 5px;
    left: 7px;
  }
}
::v-deep.el-dropdown-menu{
  >.title{
    text-align: center;
    color: #409EFF;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    letter-spacing: 3px;
  }
  >.bottom{
    display: flex;
    justify-content: space-between;
    div{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding: 15px;
    }
    >div:hover{
      cursor: pointer;
      color: #39A5EF;
    }
  }
  .item-box{
    >.top{
      display: flex;
      align-items: center;
      .img{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #39A5EF;
        text-align: center;
        line-height: 20px;
        color: #fff;
        i{
          margin-right: 0;
        }
      }
      >.title{
        margin-left: 10px;
        width: 259px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;
      }
      >.time{
        margin-left: 30px;
      }
    }
    >.content{
      width: 430px;
      margin: 0 auto;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
    }
  }
}
.index{
  ::v-deep.el-dialog{
    /*max-height: 600px;*/
    min-height: 500px;
    /*background: #761717;*/
  }
  .user-avatar{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  >.top{
    //height: 80px;
    height: 70px;
    width: 100% !important;
    padding-right: 14px!important;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 99;
    .box{
      //cursor: pointer;
      margin: 0 auto;
      //padding: 10px 0;
      width: 1300px;
      height: 70px;
      //height: 100%;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }
    .logo{
      float: left;
      //margin-left: 13px;
      padding: 12px 0 11px 19px;
      width: 53px;
      height: 55px;
      img {
        width: 53px;
        height: 55px;
      }
      //font-size: 30px;
      //font-family: JBeiWeiKaiSu;
      //align-items: center;
      //display: flex;
      //font-weight: 400;
      //color: #FFFFFF;
      //line-height: 72px;
      //p{
      //    margin: 0 10px;
      //}
    }
    .name {
      float: left;
      height: 20px;
      font-size: 20px;
      font-family: HYk2gj;
      color: #4C97FD;
      line-height: 70px;
      margin-left: 32px;
    }
    .tab{
      //display: flex;
      //width: 400px;
      float: left;
      height: 70px;
      margin-left: 122px;

      //line-height: 70px;
      //margin-left: -300px;
      /*justify-content: space-around;*/
      .select{
        //width: 100px;
        height: 70px;
        color: rgba(67, 146, 253, 1) !important;
        font-weight: bold;
      }
      div{
        float: left;
        //margin-left: 30px;
        height: 70px;
        line-height: 70px;
        width: 114px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        //margin-left: 20px;
        //font-family: Microsoft YaHei;
        //font-weight: 400;
        color: rgba(48, 48, 48, 1);
      }
      .stick {
        float: left;
        margin-top: 27px;
        height: 16px;
        width: 1px;
        background: #E7EDF6;
      }

      div:hover{
        font-weight: bold;
        color: #4C97FD;
      }
    }
    .info{
      float: left;
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 190px;
      /deep/.el-badge__content.is-fixed {
        right: 118px!important;
      }
      .Bell {
        float: left;
        color:#4C97FD;
        width: 14px;
        height: 18px;
        //font-size: 16px;
      }
      .stick {
        float: left;
        margin-left: 21px;
        width: 1px;
        height: 16px;
        background: #E7EDF6;
      }
      /*   .el-icon-bell {
           //margin-right: 20px;
           //margin: 23px 0 24px 248px;
           width: 14px;
           height: 18px;
           //background: #4C97FD;
           //line-height: 25px;

           padding-left: 248px;
           border-right: 1px solid #CBD2D6;
           font-size: 16px;
         }*/
    }
   /* .info:hover{
      cursor: pointer;
    }*/
  }
  /* .rightTab{
     z-index: 9999;
     position: fixed;
     right: 45px;
     top: 400px;
     width: 50px;
     //height: 152px;
     background: #FFFFFF;
     border: 1px solid #E6E9EF;
     border-radius: 6px;
     .box{
       width: 48px;
       height: 50px;
       border-bottom: 1px solid #E6E9EF;
       overflow: hidden;
       //cursor: pointer;
     }
     .before{
       color: #CBD1D5;
       text-align: center;
       font-size: 20px;
       line-height: 50px;
     }
     .after,a{
       background: #39A5EF;
       width: 48px;
       height: 50px;
       font-size: 15px;
       display: table-cell;
       vertical-align:middle;
       text-align: center;
       font-family: Microsoft YaHei;
       font-weight: 400;
       color: #FFFFFF;
     }
     .feedback,.phoneNum{
       width: 48px;
       height: 100px;
       &:hover {
         transform: translate(0,-50%);
       }
     }
     .top{
       width: 48px;
       height: 100px;
       &:hover {
         transform: translate(0,-50%);
       }
     }
   }*/
}
::v-deep.el-dialog{
  background: #4A6BD6;
}
.changeWidth ::v-deep.el-dialog {
  background-size:100% 100%!important;
  min-height: 480px!important;

}
/deep/.el-dropdown-menu__item{
  //padding: 0;
  line-height: 30px;
  color: rgba(48, 48, 48, 1);
}
/deep/.el-badge__content.is-fixed{
  right: 110px;
}
/deep/.el-badge__content.is-fixed.is-dot{
  top: 6px;
  right: 15px;
}
.allMark{
  color: #4492FD;
}
</style>
