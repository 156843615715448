import request from "@/utils/request";

//密码登录
export function getLogin(data) {
  return request({
    url: `api/tmStudentLogin/login`,
    method: 'post',
    data
  })
}
//获取验证码
export function getCode(data) {
  return request({
    url: `api/tmStudentLogin/getCode`,
    method: 'post',
    data
  })
}
//获取验证码 未注册
export function getCodeByPhone(data) {
  return request({
    url: `api/tmStudentLogin/getCodeByPhone`,
    method: 'post',
    data
  })
}

//获取验证码
export function getApplyCode(data) {
  return request({
    url: `api/tmStudentTest/getCode`,
    method: 'post',
    data
  })
}
//获取验证码
export function applyfor(data) {
  return request({
    url: `api//tmStudentTest/apply`,
    method: 'post',
    data
  })
}
//验证码登入
export function passwordLogin(data) {
  return request({
    url: `api/tmStudentLogin/loginCode`,
    method: 'post',
    data
  })
}
//获取用户信息
export function getUserInfo() {
  return request({
    url: `api/getStudentInfo`,
    method: 'get',
  })
}
//忘记密码
export function getRePassword(data) {
  return request({
    url: `api/tmStudentLogin/newPassword`,
    method: 'post',
    data
  })
}
//消息列表
export function getMessage(data) {
  return request({
    url: `api/tmNoticeLog/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//未读消息条数
export function getUnRead(data) {
  return request({
    url: `api/tmNoticeLog/noticeCount`,
    method: 'post',
    data
  })
}
//改变消息阅读状态
export function getRead(data) {
  return request({
    url: `api/tmNoticeLog/edit`,
    method: 'post',
    data
  })
}
//全部已读
export function getAllRead(data) {
  return request({
    url: `api/tmNoticeLog/editAll?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//获取进度
export function getStuSpeed(data) {
  return request({
    url: `api/tmWelcome/speed`,
    method: 'post',
    data
  })
}
//
export function getChange(data) {
  return request({
    url: `api/tmStudentFeedback/add`,
    method: 'post',
    data
  })
}

/* 获取注册还是试学*/
export function getConfigKey() {
  return request({
    url: `api/tmStudentTest/need/trial`,
    method: 'get'
  })
}
