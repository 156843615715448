<template>
  <div class="messageAll">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="1000px"
      top="10vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div slot="title" class="header-title" style="padding: 20px 0;border: 1px solid rgba(0, 0, 0, 0.25);">
        <span class="title-name" style="color: #409EFF;letter-spacing: 3px;font-weight: 600">全部消息</span>
        <!--                <span class="title-read" @click="readAll">全部标为已读</span>-->
        <!--                <span class="title-empty" @click="empty">清空</span>-->
      </div>

      <div class="scrollbar" style="height: 500px;overflow: auto">
        <el-scrollbar>
          <div v-for="(i,index) in listAll" :key="index" class="box" style="margin-top: 10px" @click="getDetail(i)">
            <div class="item-box" style="overflow: hidden; width: 880px">
              <div style="float: left;padding-top: 15px">
                <el-badge is-dot class="item" :hidden="i.status===1">
                  <div class="XiaoXi"><i class="el-icon-message-solid" style="font-size: 25px" /></div>
                </el-badge>
              </div>
              <div style="float: left;margin-left: 20px;height: 68px;width: 770px">
                <div style="overflow: hidden;padding-top: 9px">
                  <div
                    style="text-align:left;float: left;font-size: 16px;width: 600px;
                      overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: 600"
                    :class="{'read':i.status===1}"
                  >{{ i.title }}</div>
                  <div style="float: right;font-size: 14px" :class="{'read':i.status===1}">{{ i.createTime }}</div>
                </div>
                <div :class="{'read':i.status===1}" style="font-size: 14px;padding-top: 6px; text-align: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{ i.content }}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="pageBox" />
      </div>
      <div style="text-align: right;" class="page">
        <el-pagination
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout=" prev, pager, next, jumper"
          :total="currentTotal"
          @current-change="currentChange"
        />
      </div>
      <div class="Posi">
        <span style="color: #4492FD" @click.stop="readAll">全部标为已读</span>
      </div>
    </el-dialog>

    <message-detail :detail-show="detailShow" :detail="detail" @detailClose="detailClose" />

  </div>
</template>

<script>
import MessageDetail from './messageDetail'
import { getAllRead } from '../api/login'
export default {
  name: 'MessageAll',
  components: { MessageDetail },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    listAll: Array,
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      detailShow: false,
      detail: {},
      list: [],
      currentPage: 1,
      pageSize: 6,
      currentTotal: 0
    }
  },
  watch: {
    total: {
      handler(val) {
        this.currentTotal = val
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    readAll(data) {
      console.log(data)
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      getAllRead({ studentId: this.$store.state.user.userInfo.id, status: 1 }).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.$emit('getAll', obj)
          this.$emit('getList')
        }
      })
    },
    // empty(){
    //   console.log ('清空')
    // },
    getDetail(data) {
      this.detail = data
      this.detailShow = true
    },
    detailClose() {
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      this.detailShow = false
      this.$emit('getAll', obj)
    },
    currentChange(val) {
      this.currentPage = val
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      this.$emit('getAll', obj)
    }
  }

}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //background-color: #8c8c8c;
  background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 0;
}

/deep/.el-dialog__body{
  padding: 0 20px 30px;
  color: rgba(48, 48, 48, 1);
  margin-bottom: 20px;
}
.Posi{
  padding-bottom: 10px;
  position: absolute;
  top: 570px;
  left: 35px;
  span{
    font-size: 14px;
  }

  span:hover{
    cursor: pointer;
    color: #39A5EF;
  }
}
/deep/.el-dialog__header{
  position: relative;
}
/deep/.el-dialog__headerbtn{
  font-size: 20px;
}
.XiaoXi{
  position: relative;
  width: 38px;
  height: 38px;    border-radius: 50%;
  background: #39A5EF;
  text-align: center;
  line-height: 20px;
  color: #fff;
margin-left: 50px;
  .el-icon-message-solid{
    font-size: 25px;
    position: absolute;
    top: 5px;
    left: 7px;
  }
}
/deep/.el-dialog__header{
  padding: 0;
}
    .read{
        color: #999999;
    }
    .messageAll{
        ::v-deep.el-dialog{
            /deep/.el-dialog__header{
                border-bottom: 1px solid #e8e8e8;
                width: 95%;
                margin: 0 auto;
                //padding: 20px 20px 10px 0;
            }
            .header-title{
                >.title-name{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 18px;
                }
                >.title-read{
                    padding-left: 850px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                }
                >.title-read:hover,.title-empty:hover{
                    cursor: pointer;
                }
                .title-empty{
                    padding-left: 30px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                }
            }
        }
        .box{
          width: 100% !important;
            .item-box{
                >.top{
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    text-align: left;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 18px;
                    .img{
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #39A5EF;
                        text-align: center;
                        line-height: 20px;
                        color: #fff;
                        i{
                            margin-right: 0;
                        }
                    }
                    >.title{
                        width: 870px;
                        margin-left: 10px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:1;
                    }
                }
                >.content{
                    width: 1009px;
                    text-align: left;
                    margin: 0 auto;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;
                }
            }
        }
        .box:hover{
            background-color: #ecf5ff;
            color: #66b1ff;
            cursor: pointer;
        }
    }

</style>
