import axios from 'axios'
import {Notification, MessageBox, Message} from 'element-ui'
import store from '@/store'
import errorCode from '@/utils/errorCode'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '',
  // 超时
  timeout: 200000
})
// request拦截器
service.interceptors.request.use(config => {
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  // let {accessToken, tokenType} = store.state.user.userInfo;
  // config.headers['Blade-Auth'] = "bearer "+store.state.user.token
  if (store.state.user.token) {
    config.headers.Authorization ="Bearer "+ store.state.user.token;    //将token放到请求头发送给服务器
    if(config.url.includes('/system/dict/type/listAll')) delete config.headers.Authorization
    if(config.url.includes('/tmHome/adCompanydynamicsList')) delete config.headers.Authorization
    if(config.url.includes('/tmHome/getAdInfo')) delete config.headers.Authorization
    if(config.url.includes('tmHome/adPropagateList')) delete config.headers.Authorization
    if(config.url.includes('/tmStudentLogin/login')) delete config.headers.Authorization
    if(config.url.includes('/tmStudentLogin/loginCode')) delete config.headers.Authorization
    if(config.url.includes('/tmStudentLogin/getCode')) delete config.headers.Authorization
    if(config.url.includes('/tmStudentLogin/newPassword')) delete config.headers.Authorization
    if(config.url.includes('api/teacher/invitation/linkAddress')) delete config.headers.Authorization
    if(config.url.includes('api/tmStudentUser/invitationAdd')) delete config.headers.Authorization
    if(config.url.includes('api/tmStudentLogin/getCodeByPhone')) delete config.headers.Authorization

    return config;

  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;

    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      localStorage.removeItem('token')
      console.log(localStorage.getItem('token'))
      if(!store.state.user.isTip){
        store.commit('SET_IS_TIP',true)
        MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
              confirmButtonText: '重新登录',
              showCancelButton:false,
              cancelButtonText: false,
              type: 'warning'
            }
        ).finally(()=>{
          location.href = '/index';
          store.commit('SET_IS_TIP',false)
        })
      }

    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else if(code===10001){
      //抽奖接口异常
      Message({
        message: msg,
        type: 'warning'
      })
    } else if(code===10002){
      // 单词考试接口警告
      Message({
        message: msg,
        type: 'warning'
      })
    } else if(code === 10401){
      localStorage.removeItem('token')
      //账号被顶
      if(!store.state.user.isTip){
        store.commit('SET_IS_TIP',true)
        MessageBox.confirm(msg, '系统提示', {
              confirmButtonText: '重新登录',
              showCancelButton:false,
              cancelButtonText: false,
              type: 'warning'
            }
        ).finally(()=>{
          location.href = '/unLogin';
          store.commit('SET_IS_TIP',false)
        })
      }
    } else if (code === 55555) {
      return res.data
    } else if (code !== 200) {
      Notification.error({
        title: msg
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let {message} = error;
    let {status} = error.request;

    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    if(status === 401){
      localStorage.removeItem('token')
      MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          showCancelButton: false,
          type: 'warning'
        }
      ).then(() => {
        // // eslint-disable-next-line no-debugger
        // debugger
        window.location.replace('/index')
        // this.$router.push('/unLogin')
      })
    }else{
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
