import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import download from './utils/download'
import { parseTime } from "./utils/ruoyi";
import VueLuckyCanvas from '@lucky-canvas/vue'
//转盘插件
Vue.use(VueLuckyCanvas)
Vue.config.productionTip = false

/* 导入 axios */
import axios from '@/utils/request'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

//引入moment
import moment from 'moment';

/*引入vue-video-player*/
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)
/* 导入element */
import ElementUI from 'element-ui';

ElementUI.Dialog.props.lockScroll.default = false;
import 'element-ui/lib/theme-chalk/index.css';

import './permission' // permission control

/* 引入全局css */
import '@/style/base.css'
import '@/style/inputBox.css'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import '@/assets/font/typing/index.css'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
//全局变量
//   Vue.prototype.$downloadUrl = 'https://teaching-management.nos-eastchina1.126.net/';
Vue.prototype.$downloadUrl = 'https://oos-hazz.ctyunapi.cn/teaching-manage/';
Vue.prototype.$moment = moment
Vue.prototype.$download = download
Vue.prototype.parseTime = parseTime
const pic = require('./assets/images/logo.png')
Vue.prototype.$pic = pic


//路由跳转回顶部
// router.afterEach(() => {
//     window.scrollTo(0, 0);
// })

Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
