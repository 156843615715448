import router from './router'
import {getStore} from '@/utils/store'
import {Message} from "element-ui";

const whiteList = ['/index', '/notYetOpen', '/DynamicUnlogin', '/submit', '/invitation',]
const routerList = [
    '/home',
    '/personal', '/answerQuestions', '/answerQuestionsDetail', '/Dynamicpages',
    '/course',
    '/homework', '/wordLearning', '/wordDictation', '/viewTestPaper',
    '/homeworkDetail', '/exercisesDetail', '/homeworkList', '/test', '/testDetail',
    '/exercises', '/exercisesDetail', '/homeworkSubmitted',
    '/study',
    '/wordExercise', '/wordExerciseDetails', '/wordExerciseDetails',
    '/material', '/problem', '/problemDetail', '/videoPage',
    '/typingSetting',
    '/typingStart', '/interviewQuestionBank', '/interviewQuestionDetail','/turntable']

router.beforeEach((to, from, next) => {
    if (getStore({name: 'token'})) {
        if (whiteList.indexOf(to.path) !== -1) {
            next({path: '/home'})
        } else {
            if (routerList.indexOf(to.path) !== -1) {
                next()
            } else {
                // 访问的页面系统中不存在
                next({path: '/home'})
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            Message('请先登录')
            next('/index') // 否则全部重定向到未登录页
        }
    }
})

router.afterEach(() => {
    window.scrollTo(0, 0);
})
