// import { login, logout, getInfo } from '@/api/login'
// import { getToken } from '@/utils/auth'
import { getStore ,setStore} from '@/utils/store'
// import { getUser } from '../../api/system/user'

const user = {
  state: {
    token: getStore({ name: 'token' })||'',
    userInfo:getStore({name:'userInfo'})||{},

  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setStore({ name: 'token', content: token })
    },
    SET_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: userInfo })
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_IS_TIP:(state, status) => {
      state.isTip=status
    }

  },

  actions: {
    // 退出系统
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_ROLES', [])
    //       commit('SET_PERMISSIONS', [])
    //       removeToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_INFO',{})
        resolve()
      })
    }
  }
}

export default user
