const wordExercise = {
    state: {
        wordKey: '',
        viewTestResults: false, // 查看测试结果
        testResultsItem: {},

    },
    mutations: {
        SET_WORD_KEY(state, str) {
            state.wordKey = str
        },
        SET_VIEW_TEST_RESULTS(state, data) {
            state.viewTestResults = data.open
            state.testResultsItem = data.item
        }
    },
    actions: {}
}

export default wordExercise
