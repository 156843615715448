
import {getStore, setStore} from "@/utils/store";
import { getDictList } from '@/api/dict'


const dict = {
    state: {
        dictTree: getStore({ name: 'dictTree' }) || {},
    },
    actions: {
        GET_DICT_LIST({commit}) {
            getDictList().then(res => {
                if (res.code === 200) {
                    const dictTree = {};
                    res.data.forEach(i => {
                        dictTree[i.dictType] = {}
                        const obj = {};
                        const listClass = {};
                        const arr = [];
                        const remark = [];
                        i.dictDataList.map(childDict => {
                            obj[childDict.dictValue] = childDict.dictLabel
                            listClass[childDict.dictValue] = childDict.listClass
                            arr.push({
                                value: childDict.dictValue,
                                label: childDict.dictLabel,
                                status: childDict.status,
                                listClass: childDict.listClass,
                            })
                            remark.push(childDict.dictLabel)
                        });
                        dictTree[i.dictType] = {
                            dictName: i.dictType,
                            dictType: i.dictName,
                            obj,
                            listClass,
                            arr,
                            remark
                        }
                    });
                    commit('SET_DICT_TREE', dictTree)
                }
            })
        }
    },
    mutations: {
        SET_DICT_TREE(state, dictTree) {
            state.dictTree = dictTree;
            setStore({ name: 'dictTree', content: dictTree })
        }
    }
}

export default dict
