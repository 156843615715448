import Vue from 'vue'
import VueRouter from 'vue-router'
import indexLayout from '../layout/indexLayout'


// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

/**
 * 维护此路由时请注意
 * 需要将对应的路由地址添加至permission.js文件whiteList或routerList中
 * whiteList为白名单路由，无需登录即可访问
 * routerList为鉴权路由，需要登录访问
 * @type {[{redirect: string, path: string}, {path: string, component: (function(): Promise<*>), name: string}, {path: string, component: (function(): Promise<*>), name: string}, {path: string, component: (function(): Promise<*>), name: string}, {path: string, component: (function(): Promise<*>), name: string}, null, null]}
 */

/**
 * meta 说明
 *  module:模块名称
 *  requiresLogin:是否需要登录访问
 *
 * */
const routes = [
    {
        path: '/',
        redirect: 'index'
    }, {
        path: '/index',
        name: 'loginIndex',
        component: () => import('../views/unLogin'),
    },
    {
        path: '/notYetOpen',
        name: 'notYetOpen',
        component: () => import('../views/unLogin/notYetOpen'),
    }, {
        path: "/DynamicUnlogin",
        name: "DynamicUnlogin",
        component: () => import("../views/Dynamicpages/DynamicUnlogin")
    }, {
        path: '/invitation',
        name: 'Invitation',
        component: () => import('../views/invitation'),
    }, {
        path: '/submit',
        name: 'SubmitSuccess',
        component: () => import('../views/invitation/SubmitSuccess'),
    }, {
        path: '/home',
        component: indexLayout,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('../views/index'),
            },
            {
                path: '/personal',
                name: 'personalCenter',
                component: () => import('../views/user'),
            },
            {
                path: '/answerQuestions',
                name: 'answerQuestions',
                component: () => import('../views/answerQuestions/answerQuestions')
            },
            {
                path: '/answerQuestionsDetail',
                name: 'answerQuestionsDetail',
                component: () => import('../views/answerQuestions/answerQuestionsDetail')
            },
            {
                path: "/Dynamicpages",
                name: "Dynamicpages",
                component: () => import("../views/Dynamicpages/Dynamicpages")
            },
            {
                path: '/course',
                name: 'courseAssignments',
                component: () => import('../views/courseAssignments'),
                children: [
                    {
                        path: '/homework',
                        name: 'homework',
                        component: () => import('../views/courseAssignments/homework')
                    }, {
                        path: '/wordLearning', // 单词学习
                        name: 'WordLearning',
                        component: () => import('../views/courseAssignments/WordLearning')
                    }, {
                        path: '/wordDictation', // 单词听写
                        name: 'WordDictation',
                        component: () => import('../views/courseAssignments/WordDictation')
                    }, {
                        path: '/viewTestPaper', // 查看试卷
                        name: 'ViewTestPaper',
                        component: () => import('../views/courseAssignments/ViewTestPaper')
                    },
                    {
                        path: '/homeworkDetail',
                        name: 'homeworkDetail',
                        component: () => import('../views/courseAssignments/homeworkDetail')
                    },
                    {
                        path: '/exercisesDetail',
                        name: 'exercisesDetail',
                        component: () => import('../views/courseAssignments/exercisesDetail')
                    },

                    {
                        path: '/homeworkList',
                        name: 'homeworkList',
                        component: () => import('../views/courseAssignments/homeworkList')
                    },
                    {
                        path: '/test',
                        name: 'test',
                        component: () => import('../views/courseAssignments/test')
                    },
                    {
                        path: '/testDetail',
                        name: 'testDetail',
                        component: () => import('../views/courseAssignments/testDetail')
                    },
                    {
                        path: '/exercises',
                        name: 'exercises',
                        component: () => import('../views/courseAssignments/exercises')
                    },
                    {
                        path: '/exercisesDetail',
                        name: 'exercisesDetail',
                        component: () => import('../views/courseAssignments/exercisesDetail')
                    },
                    {
                        path: '/homeworkSubmitted',
                        name: 'homeworkSubmitted',
                        component: () => import('../views/courseAssignments/homeworkSubmitted')
                    },

                ]
            },
            {
                path: '/study',
                name: 'study',
                component: () => import('../views/study'),
                children: [
                    {
                        path: '/material',
                        name: 'material',
                        component: () => import('../views/study/material')
                    }, {
                        path: '/problem',
                        name: 'problem',
                        component: () => import('../views/study/exercises')
                    },
                    {
                        path: '/problemDetail',
                        name: 'studyExercisesDetail',
                        component: () => import('../views/study/exercisesDetail')
                    },
                    {
                        path: '/videoPage',
                        name: 'videoPage',
                        component: () => import('../views/study/videoPage')
                    },
                ]
            },
            {
                path: '/typingSetting',
                name: 'TypingSetting',
                component: () => import('../views/typing/index')
            }, {
                path: '/wordExercise',
                name: 'WordExercise',
                meta: {module: '专业英语测评', requiresLogin: true},
                component: () => import('../views/basicSkills/wordExercise/Index.vue'),
                children: [
                    {
                        path: '',
                        name: 'WordExerciseProgress',
                        meta: {module: '专业英语测评进度', requiresLogin: true},
                        component: () => import('@/views/basicSkills/wordExercise/Progress.vue')
                    }, {
                        path: '/wordExerciseDetails',
                        name: 'WordExerciseDetails',
                        meta: {module: '专业英语测评详情', requiresLogin: true},
                        component: () => import('@/views/basicSkills/wordExercise/Details.vue')
                    }
                ]
            },
            {
                path: '/typingStart',
                name: 'TypingStart',
                component: () => import('../views/typing/typing')
            },
            {
                path: '/interviewQuestionBank',
                name: 'InterviewQuestionBank',
                component: () => import('../views/interviewQuestionBank/index'),
            }
        ]
    },
    {
        path: '/interviewQuestionDetail',
        name: 'interviewQuestionDetail',
        component: () => import('../views/interviewQuestionBank/views/interviewQuestionDetail')
    },
    {
        path: '/turntable',
        name: 'turntable',
        meta: {module: '幸运转盘', requiresLogin: true},
        component: () => import('../views/turntable')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (window._hmt && window.location.href.includes("stu.myhope365.com")) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;

    next()
})
router.afterEach(() => {
    const dom = document.getElementById('app')
    if (dom) {
        dom.scrollTop = 0
    }
})
export default router
