import Vue from 'vue'

export const isMobile = () => {
  const info = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return (info || false) && true
}

export const copy = text => {
  const use = Vue.prototype
  return use.$copyText(text).then(() => true).catch(() => false)
}
