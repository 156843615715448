import { getStore ,setStore} from '@/utils/store'

const typing = {
  state: {
    // token: getStore({ name: 'token' })||'',
    article: getStore({ name: 'article' })||{},
    record: getStore({ name: 'record' })||{},
    isStart: getStore({ name: 'isStart' })||false, // 此状态只会在 开始打字后变为true，一旦变为true，就不会再更改为false
    recordArr: getStore({ name: 'recordArr' }) || [], // 每一行的打字记录
  },

  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token
    //   setStore({ name: 'token', content: token })
    // },
    SET_ARTICLE: (state, article) => {
      state.article = article
      setStore({ name: 'article', content: article })
    },
    SET_RECORD: (state, record) => {
      state.record = record
      setStore({ name: 'record', content: record })
    },
    SET_IS_START: (state, isStart) => {
      state.isStart = isStart
      setStore({ name: 'isStart', content: isStart })
    },
    SET_RECORD_ARR: (state, recordArr) => {
      state.recordArr = recordArr.concat([])
      setStore({ name: 'recordArr', content: recordArr })
    }
  },
  actions: {
  }
}

export default typing
